import colormap from "colormap";

function getColors(nshades, cmap = "viridis") {
  let m = 1; // multiplication factor to get through minimum limit
  if (nshades < 9) {
    m = 2;
  }
  let colors = colormap({
    colormap: cmap,
    nshades: nshades * m,
    format: "hex",
    alpha: 1,
  });
  colors = colors.filter((item, i) => i % m === 0);
  return colors;
}

export default getColors;
