import React from "react";

import "./app.css";
import ScatterPlot from "./scatter-plot";
import Widget, { WidgetRow } from "./bricks/widget";
import ActiveRecord from "./active-record";
import rawData from "../assets/data.json";
import maps from "../assets/maps.json";
import ImageGrid from "./image-grid";
import CONFIG from "../config";
import { unique } from "../utils/utils";
import getColors from "../utils/colormap";

const IMAGES_ENDPOINT = process.env.REACT_APP_IMAGES_ENDPOINT || "http://127.0.0.1:12539/images";

export default class App extends React.Component {
  constructor() {
    super();

    // convert data object to array and include additional attributes
    const { classid2name, genusid2name } = maps;
    this.data = Object.entries(rawData).map(([key, value]) => {
      // const _splited = value.image_path.split("/");
      // const fileName = _splited[_splited.length - 1];
      const newValue = Object.assign(value);
      // newValue.image_id = fileName.split(".")[0];
      newValue.image_url = `${IMAGES_ENDPOINT}/${value.image_id}`;
      newValue.id = key;
      newValue.class = classid2name[newValue.class_id];
      newValue.genus = genusid2name[newValue.genus_id];
      newValue.pred_genus = genusid2name[newValue.pred_genus_id];
      return newValue;
    });

    // create list of unique genus ids and add color attribute
    const uniqueGenusIds = unique(this.data.map((item) => item.genus_id));
    const colors = getColors(uniqueGenusIds.length, CONFIG.colormap);
    this.data = this.data.map((item) => {
      const newItem = Object.assign(item);
      newItem.color = colors[item.genus_id];
      return newItem;
    });

    this.state = {
      activeRecord: null,
      selectedRecords: [],
    };
  }

  setActiveRecords(record) {
    this.setState({ activeRecord: record });
  }

  addSelectedRecord(record) {
    const { selectedRecords } = this.state;
    const isSelected = selectedRecords.some((item) => item.image_id === record.image_id);
    if (!isSelected) {
      this.setState({ selectedRecords: [...selectedRecords, record] });
    }
  }

  clearAndAddSelectedRecords(records) {
    this.setState({ selectedRecords: records });
  }

  render() {
    const { activeRecord, selectedRecords } = this.state;
    return (
      <>
        <Widget title="Embedding Visualization">
          <p>
            The visualization shows 3640 fungi samples from the public test set of the
            {" "}
            <a href="https://sites.google.com/view/danish-fungi-dataset">
              Danish Fungi 2020 Mini (DF20M) dataset
            </a>
            .
            The embeddings are generated using Swin Transformer trained on 32753 training samples
            using combination of Cross Entropy Loss and Cosine Similarity Loss.
            The embeddings are then projected to 2D space using t-SNE dimensionality reduction
            technique.
          </p>
        </Widget>
        <WidgetRow>
          <Widget title="Embedding Scatter Plot (t-SNE)" colSize={1}>
            <ScatterPlot
              data={this.data}
              height={600}
              width={600}
              onClick={(record) => this.addSelectedRecord(record)}
              onBrush={(records) => this.clearAndAddSelectedRecords(records)}
              onActiveRecord={(record) => this.setActiveRecords(record)}
              onActiveRecordLeave={() => null}
            />
          </Widget>
          <Widget title="Selected Image" colSize={1}>
            <ActiveRecord record={activeRecord} />
          </Widget>
        </WidgetRow>
        <Widget title="Selected Images for Comparison" colSize={1}>
          <ImageGrid records={selectedRecords} />
        </Widget>
      </>
    );
  }
}
