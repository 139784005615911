import { Table } from "react-bootstrap";
import React from "react";

import "./active-record.css";
import Image from "./bricks/image";
import { recordShape } from "../config";

function ActiveRecord(props) {
  const { record } = props;
  return record !== null ? (
    <div className="active-record">
      <Image src={record.image_url} alt="Active record." />
      <Table borderless striped>
        <tbody>
          <tr>
            <td>Image ID</td>
            <td>{record.image_id}</td>
          </tr>
          <tr>
            <td>Image URL</td>
            <td>
              <a href={record.image_url}>{record.image_url}</a>
            </td>
          </tr>
          <tr>
            <td>x</td>
            <td>{record.emb_x}</td>
          </tr>
          <tr>
            <td>y</td>
            <td>{record.emb_y}</td>
          </tr>
          <tr>
            <td>Class</td>
            <td>{`(${record.class_id}) ${record.class}`}</td>
          </tr>
          <tr>
            <td>Genus</td>
            <td>{`(${record.genus_id}) ${record.genus}`}</td>
          </tr>
          <tr>
            <td>Expected Genus</td>
            <td>{`(${record.pred_genus_id}) ${record.pred_genus}`}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  ) : (
    <div className="active-record active-record-empty">
      Hover over any point in scatter plot to visualize image.
    </div>
  );
}

ActiveRecord.propTypes = {
  record: recordShape,
};
ActiveRecord.defaultProps = {
  record: null,
};

export default ActiveRecord;
