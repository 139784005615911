import React from "react";
import { Card } from "react-bootstrap";

import "./widget.css";
import PropTypes from "prop-types";

export function WidgetRow(props) {
  const { children } = props;
  return (
    <div className="widget-row">
      {children}
    </div>
  );
}

WidgetRow.propTypes = {
  children: PropTypes.node.isRequired,
};

function Widget(props) {
  const { title, colSize, children } = props;
  return (
    <Card className="widget" style={{ flex: colSize }}>
      <Card.Header>{title}</Card.Header>
      <Card.Body className="widget-body">{children}</Card.Body>
    </Card>
  );
}

Widget.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  colSize: PropTypes.number,
};
Widget.defaultProps = {
  colSize: 1,
};

export default Widget;
