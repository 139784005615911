import PropTypes from "prop-types";

const CONFIG = {
  colormap: "viridis",
};

export default CONFIG;

export const recordShape = PropTypes.shape({
  image_id: PropTypes.string.isRequired,
  image_url: PropTypes.string.isRequired,
  emb_x: PropTypes.number.isRequired,
  emb_y: PropTypes.number.isRequired,
  class_id: PropTypes.number.isRequired,
  class: PropTypes.string.isRequired,
  genus_id: PropTypes.number.isRequired,
  genus: PropTypes.string.isRequired,
  pred_genus_id: PropTypes.number.isRequired,
  pred_genus: PropTypes.string.isRequired,
});
