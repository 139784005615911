import React, { createRef } from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import "./image.css";

export default class Image extends React.Component {
  imageRef = createRef();

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const image = this.imageRef.current;
    if (!image.complete) {
      this.addImageLoadingListener();
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidUpdate(prevProps) {
    const { src } = this.props;
    const image = this.imageRef.current;
    if (src !== prevProps.src && !image.complete) {
      this.setState({ isLoading: true }, () => this.addImageLoadingListener());
    }
  }

  addImageLoadingListener() {
    const image = this.imageRef.current;
    image.addEventListener("load", () => {
      // image has loaded
      // const hasLoaded = this.imageRef.complete && this.imageRef.naturalHeight !== 0;
      this.setState({ isLoading: false });
    });
    // image.addEventListener("error", (err) => console.log(err));
  }

  render() {
    const { src, alt } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="image-wrapper">
        <img src={src} alt={alt} ref={this.imageRef} style={{ opacity: isLoading ? 0.5 : 1 }} />
        {isLoading ? (
          <Spinner
            className="image-spinner"
            variant="primary"
            animation="border"
            role="status"
          />
        ) : null}
      </div>
    );
  }
}

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};
Image.defaultProps = {
  src: null,
  alt: null,
};
