import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";

import "./image-grid.css";
import { recordShape } from "../config";
import Image from "./bricks/image";

function GridItem(props) {
  const { record } = props;
  return (
    <div className="image-grid-item" style={{ borderColor: record.color }}>
      <Image src={record.image_url} alt="Sample to compare." />
      <span>{`Genus: (${record.genus_id}) ${record.genus}`}</span>
      <span>{`Expected Genus: (${record.pred_genus_id}) ${record.pred_genus}`}</span>
    </div>
  );
}

GridItem.propTypes = {
  record: recordShape.isRequired,
};

function listsEqual(list1, list2) {
  let equal = true;
  if (list1.length !== list2.length) {
    equal = false;
  } else {
    for (let i = 0; i < list1.length; i++) {
      if (list1[i] !== list2[i]) {
        equal = false;
        break;
      }
    }
  }
  return equal;
}

class ImageGrid extends React.Component {
  constructor() {
    super();
    this.state = {
      displayedRecords: [],
    };
  }

  componentDidMount() {
    const { records, pageSize } = this.props;
    if (records.length > 0) {
      const filteredRecords = records.filter((item, i) => i < pageSize);
      this.setState({ displayedRecords: filteredRecords });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { records } = this.props;
    const { displayedRecords } = this.state;
    const recordsChanged = !listsEqual(records, nextProps.records);
    const displayedRecordsChanged = !listsEqual(displayedRecords, nextState.displayedRecords);
    return recordsChanged || displayedRecordsChanged;
  }

  componentDidUpdate(prevProps) {
    const { records, pageSize } = this.props;
    const recordsChanged = !listsEqual(records, prevProps.records);
    if (recordsChanged) {
      const filteredRecords = records.filter((item, i) => i < pageSize);
      this.setState({ displayedRecords: filteredRecords });
    }
  }

  showMoreRecords() {
    const { records, pageSize } = this.props;
    const { displayedRecords } = this.state;
    const totalSize = displayedRecords.length + pageSize;
    const filteredRecords = records.filter((item, i) => i < totalSize);
    this.setState({ displayedRecords: filteredRecords });
  }

  renderRecords() {
    const { displayedRecords } = this.state;
    return displayedRecords.map((item) => (<GridItem key={item.image_id} record={item} />));
  }

  render() {
    const { records } = this.props;
    const { displayedRecords } = this.state;
    let content;
    if (records.length === 0) {
      content = (
        "Click on points or apply brush in the scatter plot"
        + " to compare multiple images at once."
      );
    } else {
      content = this.renderRecords();
    }
    const showButton = records.length > displayedRecords.length;
    return (
      <>
        <div key="image-grid" className="image-grid">{content}</div>
        {showButton ? (
          <Button
            key="image-grid-button"
            className="image-grid-button"
            variant="link"
            onClick={() => this.showMoreRecords()}
          >
            Load more images
          </Button>
        ) : null}
      </>
    );
  }
}

ImageGrid.propTypes = {
  records: PropTypes.arrayOf(recordShape),
  pageSize: PropTypes.number,
};
ImageGrid.defaultProps = {
  records: [],
  pageSize: 20,
};

export default ImageGrid;
